import styled from 'styled-components';

const DepositionsStyle = styled.div`
  padding-top: 60px;
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 70px;

    .divider {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;
      width: 100%;

      .one {
        max-width: 354px;
        width: 100%;
        height: 1px;
        background: rgba(181, 181, 181, 0.32);
      }

      .two {
        position: absolute;
        width: 94px;
        height: 8px;
        background: #c79852;
      }
    }

    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 163.19%;
      letter-spacing: -0.06em;
      color: #000000;
    }

    .slide {
      width: 100%;
      display: flex;

      .item {
        padding-bottom: 10px;

        .img {
          width: 100%;
          display: flex;
          justify-content: center;

          img {
            max-width: 182px;
            max-height: 182px;
            filter: drop-shadow(10px 10px 30px rgba(55, 62, 201, 0.23));
            border-radius: 50%;
            border: 3px solid #c79852;
          }
        }

        .item-info {
          display: flex;
          flex-direction: column;
          padding-top: 10px;

          .info-description {
          }
        }
      }
    }
  }
`;

export const Prev = styled.div`
  position: absolute;
  @media (min-width: 1580px) {
    top: 30%;
  }
  top: 40%;
  left: 0%;
  z-index: 999;
  cursor: pointer;
`;

export const Next = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0%;
  @media (min-width: 1580px) {
    top: 30%;
  }
  top: 40%;
  z-index: 999;
`;

export const Item = styled.div`
  @media (max-width: 767px) {
    align-content: center;
    flex-direction: column;
    display: flex;
    margin: auto;
  }
`;

export const TitleH4 = styled.h4`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 116.69%;
  color: #c79852;
`;

export const Img = styled.img`
  width: 182px;
  height: 182px;
  filter: drop-shadow(10px 10px 30px rgba(55, 62, 201, 0.23));
  border-radius: 50%;
  border: 3px solid #c79852;
`;

export const Text = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 163.19%;
  letter-spacing: 0.005em;
  color: #acacac;
  text-align: left;
  padding-top: 10px;
`;

export const Subtitle = styled.h6`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 163.19%;
  letter-spacing: 0.005em;
  color: #c79852;
  padding-bottom: 10px;
  text-align: left;
  &:after {
    content: '';
    border-bottom: 2px solid #000;
    display: block;
    position: absolute;
    width: 46px;
    margin-top: 3px;
  }
`;
export default DepositionsStyle;
