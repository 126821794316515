import React from 'react';
import { Plan } from '@app/types/Plan';
import { FormattedMessage } from 'react-intl';

/** Dummy Plan data. */
export const samplePlanData: Plan[] = [
  {
    title: 'landingPage.plans.player.title', // ID referring to translation
    descricao: 'landingPage.plans.player.description', // ID referring to translation
    text: (
      <span>
        <p>
          <FormattedMessage id="landingPage.plans.player.text1" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text2" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text3" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text4" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text5" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text6" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text7" />
        </p>
      </span>
    ),
  },
  {
    title: 'landingPage.plans.clubs.title',
    descricao: 'landingPage.plans.clubs.description',
    text: (
      <span>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text1" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text2" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text3" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text4" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text5" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text5" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text6" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text7" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text8" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text9" />
        </p>
      </span>
    ),
  },
  {
    title: 'landingPage.plans.agents.title',
    descricao: 'landingPage.plans.agents.description',
    text: (
      <span>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text1" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text2" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text3" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text4" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text5" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text5" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text6" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text7" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text8" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text9" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text10" />
        </p>
      </span>
    ),
  },
  // {
  //   title: 'Para Treinadores',
  //   descricao: `Os treinadores cadastrados na plataforma têm a possibilidade de  receber propostas e enviar seu material  para as melhores ofertas de trabalho do mercado do futebol.`,
  //   text: (
  //     <span>
  //       Quantas vezes você já chegou no final do campeonato estadual sem saber se vai continuar tendo ou não um
  //       contrato?
  //       <p />
  //       E você, que em uma troca de categoria ou em uma simples "peneira" foi dispensado na categoria de base e ficou
  //       sem saber pra onde ir, até pensando em desistir de seu sonho? Com esse cenário de incertezas, muitos jogadores
  //       (bons de bola), acabam abandonando o futebol, que é o que verdadeiramente amam, para se dedicarem a outras
  //       atividades.
  //       <p />
  //       Tudo isso em busca de estabilidade ou uma certeza de emprego. Você já deve ter escutado pessoas dizendo: “Eu era
  //       craque, mas não tive oportunidade.” ou “Ah, se eu tivesse tido oportunidades..”. Infelizmente, é bastante comum
  //       escutarmos esses tipos de afirmações em nosso país.
  //       <p />
  //       Já parou para pensar que você pode dar o seu melhor, fazer um campeonato impecável, pagar o preço para ser
  //       destaque no time, mas e se seu material não chegar em nenhum empresário influente do futebol, em nenhum clube
  //       grande?
  //       <p />
  //       Sabemos que essas situações são reais. Muitos jogadores não conseguiram ingressar em grandes clubes porque
  //       ninguém os apresentou para esses tão sonhados times, o que é uma realidade de mais de 94% dos jogadores
  //       brasileiros, que terminam o ano sem clube.
  //       <p />
  //       Pois bem... Trazemos a notícia de que essa realidade está prestes a mudar!
  //       <p />
  //       Depois de muito conversar com dirigentes e empresários, houve a percepção de uma enorme dificuldade em encontrar
  //       os jogadores certos e, por parte dos jogadores, dificuldade em conseguir uma oportunidade.
  //       <p />
  //       Foi assim que nasceu a Transferfut, uma plataforma que faz o intermédio entre jogadores e os times/empresários
  //       do mundo do futebol.
  //       <p />
  //       Nossa missão é potencializar seu trabalho e ajudar você, jogador, a encontrar um clube e um empresário.
  //       <p />
  //       Para se cadastrar é só clicar no botão ‘’Começar’’ logo abaixo e se inscrever gratuitamente.
  //     </span>
  //   ),
  // },
];
