import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import DepositionsStyle, { Item, Next, Prev, Img, Text, Subtitle, TitleH4 } from '@app/components/Depositions/style';

import Slider from 'react-slick';
import { FormattedMessage, useIntl } from 'react-intl';

const configSlider = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

interface PropSlideData {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  img: React.ImgHTMLAttributes<any>;
}

interface PropArrow {
  style?: React.CSSProperties;
  onClick?: () => void;
}
function SampleNextArrow({ style, onClick }: PropArrow) {
  return (
    <Next style={{ ...style }} onClick={onClick}>
      <svg width="19" height="30" viewBox="0 0 19 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.3528 16.4472L5.84474 28.6171C4.98021 29.4582 3.58226 29.4582 2.72693 28.6171L0.648393 26.5947C-0.216131 25.7536 -0.216131 24.3934 0.648393 23.5612L9.51437 14.9349L0.648393 6.30863C-0.216131 5.46747 -0.216131 4.10731 0.648393 3.27511L2.71773 1.23487C3.58226 0.393716 4.98021 0.393716 5.83554 1.23487L18.3436 13.4047C19.2173 14.2459 19.2173 15.606 18.3528 16.4472Z"
          fill="#262A7A"
        />
      </svg>
    </Next>
  );
}

function SamplePrevArrow(props: PropArrow) {
  const { style, onClick } = props;
  return (
    <Prev style={{ ...style }} onClick={onClick}>
      <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.749445 15.8433L15.2324 28.0133C16.2334 28.8545 17.8521 28.8545 18.8425 28.0133L21.2492 25.9909C22.2503 25.1498 22.2503 23.7896 21.2492 22.9574L10.9834 14.331L21.2492 5.70467C22.2503 4.86351 22.2503 3.50334 21.2492 2.67113L18.8532 0.63087C17.8521 -0.21029 16.2334 -0.21029 15.2431 0.63087L0.760096 12.8008C-0.251581 13.642 -0.251583 15.0022 0.749445 15.8433Z"
          fill="#262A7A"
        />
      </svg>
    </Prev>
  );
}

const sliderData: Array<PropSlideData> = [
  {
    id: 1,
    title: 'landingPage.deposition.depositionTitle1',
    subtitle: 'landingPage.deposition.depositionSubtitle1',
    description: 'landingPage.deposition.depositionDescription1',
    img: {
      alt: 'Jogador de futebol, nome Harlei Menezes',
      src: 'img/harley.png',
    },
  },
  {
    id: 2,
    title: 'landingPage.deposition.depositionTitle2',
    subtitle: 'landingPage.deposition.depositionSubtitle2',
    description: 'landingPage.deposition.depositionDescription2',
    img: {
      alt: 'Jogador de futebol, nome jardel',
      src: 'img/olimpio.png',
    },
  },
  {
    id: 3,
    title: 'landingPage.deposition.depositionTitle3',
    subtitle: 'landingPage.deposition.depositionSubtitle3',
    description: 'landingPage.deposition.depositionDescription3',
    img: {
      alt: 'Jogador de futebol, nome marcos',
      src: 'img/entourage_sports.jpeg',
    },
  },
  {
    id: 4,
    title: 'landingPage.deposition.depositionTitle4',
    subtitle: 'landingPage.deposition.depositionSubtitle4',
    description: 'landingPage.deposition.depositionDescription4',
    img: {
      alt: 'Jogador de futebol, nome jardel',
      src: 'img/jardel.jpeg',
    },
  },
];

const Depositions = (): React.ReactElement => {
  const intl = useIntl();

  return (
    <DepositionsStyle>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs="12" sm="12" lg="9">
            <div className="info">
              <div className="divider">
                <div className="one"></div>
                <div className="two"></div>
              </div>
              <div className="title" style={{ textTransform: 'uppercase' }}>
                <FormattedMessage id="landingPage.deposition.title" />
              </div>
            </div>
          </Col>
        </Row>
        <Slider {...configSlider}>
          {sliderData.map((item: PropSlideData) => (
            <Item key={item.id} className="d-flex justify-content-between px-5 pb-5">
              <Img className="mr-4" {...item.img} />
              <div className="ml-md-5 mt-5 mt-md-0">
                <TitleH4>{intl.formatMessage({ id: item.title })}</TitleH4>
                <Subtitle>{intl.formatMessage({ id: item.subtitle })}</Subtitle>
                <Text>{intl.formatMessage({ id: item.description })}</Text>
              </div>
            </Item>
          ))}
        </Slider>
      </Container>
    </DepositionsStyle>
  );
};

export default Depositions;
